export enum LogLevel {
  Fatal = 'fatal',
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Debug = 'debug',
  Trace = 'trace',
}

export interface LogMessage {
  message: string;
  data?: object;
}

export interface InternalLogMessage extends LogMessage {
  timestamp: string;
  formattedMessage: string;
  logLevel: LogLevel;
  loggerName: string;
}

export interface Logger {
  fatal(args: LogMessage, error?: Error): void;
  error(args: LogMessage, error?: Error): void;
  warn(args: LogMessage): void;
  info(args: LogMessage): void;
  debug(args: LogMessage): void;
  trace(args: LogMessage): void;
}

export interface Transport {
  fatal(args: InternalLogMessage, error?: Error): void;
  error(args: InternalLogMessage, error?: Error): void;
  warn(args: InternalLogMessage): void;
  info(args: InternalLogMessage): void;
  debug(args: InternalLogMessage): void;
  trace(args: InternalLogMessage): void;
}
