'use client';

import {datadogLogs, LogsInitConfiguration} from '@datadog/browser-logs';
import {Transport, InternalLogMessage} from '@/logger/types';
import {
  CLIENT_VERSION,
  CLIENT_NAME,
  DATADOG_SITE,
  DATADOG_LOG_CLIENT_TOKEN,
  getCurrentEnvironment,
  FE_DD_PROXY_ENDPOINT,
} from '@/config';

function initDatadogLogs() {
  const config: LogsInitConfiguration = {
    clientToken: DATADOG_LOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: CLIENT_NAME,
    env: getCurrentEnvironment(),
    version: CLIENT_VERSION,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    proxy: (options) => {
      return `${FE_DD_PROXY_ENDPOINT}?path=${options.path}&${options.parameters}`;
    },
  };
  datadogLogs.init(config);
}

initDatadogLogs();
const loggerInstance = datadogLogs;

function datadogTransport(): Transport {
  return {
    fatal(args: InternalLogMessage, error?: Error): void {
      const {formattedMessage, data} = args;
      loggerInstance.logger.error(formattedMessage, data, error);
    },
    error(args: InternalLogMessage, error?: Error): void {
      const {formattedMessage, data} = args;
      loggerInstance.logger.error(formattedMessage, data, error);
    },
    warn(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      loggerInstance.logger.warn(formattedMessage, data);
    },
    info(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      loggerInstance.logger.info(formattedMessage, data);
    },
    debug(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      loggerInstance.logger.debug(formattedMessage, data);
    },
    trace(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      loggerInstance.logger.debug(formattedMessage, data);
    },
  };
}

export {datadogTransport};
